import { navigateTo } from 'nuxt/app'
import type { LobList } from './../type/lob'
import type { Response } from '@/type'
import type { Lob, LobIcons } from '~/type/lob'
import type { Menu } from '~/type/role-management'

export const useAuth = defineStore({
  id: 'auth',
  state: () => ({
    loggedIn: false,
    user: <any>{},
    resetPasswordSuccess: false,
    branch: [],
    menu: {
      data: {
        menu: [],
      },
    },
    activeLob: <number | null> null,
  }),
  actions: {
    async login(credentials: { username: string; password: string }) {
      try {
        const response: Response = await $fetch('/api/auth/login', {
          method: 'POST',
          body: JSON.stringify({
            username: credentials.username,
            password: credentials.password,
          }),
        })

        this.loggedIn = true
        this.user = response.data
        this.activeLob = this.user?.line_of_business[0]?.lob_id // set default active lob

        const tokenCookie = useCookie('token')
        const userCookie = useCookie('user')
        const activeLobCookie = useCookie('active-lob')

        delete response.data?.menu

        tokenCookie.value = response.data.token
        userCookie.value = JSON.stringify(response.data)
        activeLobCookie.value = this.activeLob?.toString()
        this.resetPasswordSuccess = false
      }
      catch (error: any) {
        return error.status
      }
    },
    async getBranchByUserId(id: number) {
      try {
        const response: any = await $fetch(`/api/auth/user/${id}`, {
          headers: {
            Authorization: `Bearer ${useCookie('token').value}`,
          },
        })

        this.branch = response.data.branch
        localStorage.setItem('branch', JSON.stringify(this.branch))
        return true
      }
      catch (error: any) {
        return false
      }
    },
    mappingBranchId() {
      if (!this.isHeadOfficeBranch) {
        const arrBranchId: Array<number> = []
        this.branch.map((branch: any) => {
          return arrBranchId.push(branch.branch_id)
        })
        return arrBranchId.toString()
      }
      return ''
    },
    async forgotPassword(payload: string) {
      try {
        const response = await $fetch('/api/auth/forgot', {
          method: 'POST',
          body: JSON.stringify({
            email: payload,
          }),
        })

        return response
      }
      catch (error: any) {
        return error.status
      }
    },
    async checkToken() {
      useLoader().startLoading()
      try {
        const response: any = await $fetch('/api/auth/check', {
          headers: {
            Authorization: `Bearer ${useCookie('token').value}`,
          },
          params: {
            lob_id: useCookie('active-lob').value,
          },
        })

        this.menu = response
        useLoader().stopLoading()

        return response
      }
      catch (error: any) {
        useLoader().stopLoading()
        return error.status
      }
    },
    has(menu: string, privilege: string) {
      if (this.menu) {
        const checkMenu: any | undefined = this.menu?.data.menu.find((item: any) => item.menu_name === menu)
        if (checkMenu?.is_active) {
          const checkPrivilege: any | undefined = checkMenu.privilege.find((item: any) => item.privilege_name === privilege)
          return checkPrivilege?.is_cms
        }
        else { return false }
      }
    },
    hasPrivilege(menuId: number, privilegeId: number) {
      if (this.menu) {
        const checkMenu = this.menu?.data.menu?.find((item: Menu) => item.menu_id === menuId) as Menu | undefined
        if (checkMenu?.is_active) {
          const checkPrivilege: any | undefined = checkMenu?.privilege?.find((item: any) => item.privilege_id === privilegeId)
          return checkPrivilege?.is_cms
        }
        else { return false }
      }
    },
    async logout() {
      const token = useCookie('token')
      const user = useCookie('user')
      const activeLob = useCookie('active-lob')
      try {
        const { data, error }: any = await useSallyFetchCms('/v3/logout', {
          method: 'POST',
        })
        if (data?.value?.code === 'SAL-AUTH-200') {
          if (typeof window !== 'undefined') {
            localStorage.removeItem('branch')
            localStorage.clear()
            token.value = null
            user.value = null
            activeLob.value = null
            this.loggedIn = false
            this.user = {}
            this.branch = []
            this.activeLob = null
            await navigateTo('/login')
          }
        }
      }
      catch (error) {
        console.error(error)
      }
    },
    async getMappedLob(lobIcons: Array<LobIcons>) {
      const mappedLob = this.user.line_of_business.map((lob: Lob) => {
        return {
          lob_id: lob.lob_id,
          lob_name: lob.lob_name,
          lob_icon: lobIcons.find((item: LobIcons) => item.lob_id === lob.lob_id)?.lob_icon,
          is_selected: false,
        }
      })
      return mappedLob
    },
    async setActiveLob(id: number) {
      this.activeLob = id
      const activeLobCookie = useCookie('active-lob')
      activeLobCookie.value = id?.toString()
    },
    async setSelectedLob(id: number, lobList: Array<LobList>) {
      this.setActiveLob(id)
      lobList.map((lob: LobList) => lob.is_selected = lob.lob_id === id)
    },
  },
  getters: {
    isHeadOfficeBranch: (state) => {
      return state.user.branch_name?.toUpperCase() === 'HEAD OFFICE'
    },
    isCurrentLOB: (state) => {
      return (currentActiveLob: number): boolean => {
        return state.activeLob === currentActiveLob
      }
    },
    isSuperAdmin: (state) => {
      const userRole = state.user.role_name?.toUpperCase()
      const userLob = state.user.lob?.toUpperCase()
      const SUPER_ADMIN_ROLE = 'SUPER ADMIN'
      return userLob === '' && userRole === SUPER_ADMIN_ROLE
    },
    isMultiLob: (state) => {
      return state.user.line_of_business?.length > 1
    },
  },
})
